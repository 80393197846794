import React, { useState, useEffect, Fragment } from "react";
import Image from "next/image";
import Link from "next/link";
import footerLogo from "../../public/img/footer-logo.png";
import gplay from "../../public/img/gplay.png";
import footerimg from "../assets/images/footer.png";
import footerbgimg from "../assets/images/footer-bg-m.png";
import footerlogo from "../assets/images/footer-logo1.png";
import isoImage from "@/assets/images/iso.png";
import isoImage2 from "@/assets/images/pci-dss-webseal.png";
import pci from "@/assets/images/pci-dss-webseal.png";
import paymentimg from "@/assets/images/paymentimg.png";
import certificationbadge from "@/assets/images/certification-badge-jan.png";
import styles from "@/styles/footer.module.scss";
import { footerLink } from "@/data/footer";
import Follow_Us from "@/components/home/follow_us";
import Script from "next/script";
import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";

const Footer = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [footerLink, setFooterLink] = useState([]);

  const [showChatBotIcon, setShowChatBotIcon] = useState(true);
  const loadFooter = async () => {
    const { data } = await httpClient.get(endpoints.home.footerMenu());
    setFooterLink(data);
  };
  useEffect(() => {
    loadFooter();
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const chatScript = `
    function loadChatgen() {
      var a = window.ChatGen || [];
      if (!a.loaded) {
        var s = document.createElement('script');
        s.type = "text/javascript";
        s.async = true;
        s.src = 'https://app.chatgen.ai/cmp/chat-widget/bot.js';
        var fs = document.getElementsByTagName('script')[0];
        fs.parentNode.insertBefore(s, fs);
        s.onload=function(){
          var yourKey = '0283yMvV';
          var widgetKey = {widget_key: yourKey};
          ChatGen.init(widgetKey);
          ChatGen.startInteraction({ interactionId:542967 });
          
        }
      }
    };
    window.loadChatgen = loadChatgen;
  `;

  const loadChat = () => {
    if ((window as any).loadChatgen) {
      (window as any).loadChatgen();
      setTimeout(() => {
        setShowChatBotIcon(false);
      }, 3000);
    }
  };

  return (
    <Fragment>
      <Script
        id="chatgen-script"
        strategy="afterInteractive"
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: chatScript }}
      />
      {/* <Follow_Us /> */}
      <div className={styles.footer_desktop}>
        <footer className="footer">
          <div className={styles.footer_top}>
            <div className={styles.footer_top_image}>
              <Image
                src={footerimg}
                alt="Get Personalized Horoscope
& Exclusive sneak peek
to the latest collection"
              />
            </div>
            <div className={styles.footer_top_a}>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-lg-5 col-7">
                    <div className={styles.middle_section}>
                      <div className={styles.h4}>
                        Get Personalized <span>Horoscope </span> <br />&
                        Exclusive sneak peek <br />
                        to the latest collection
                        <br />
                      </div>
                      <div className={styles.app_logo}>
                        <Image
                          className="img-fluid "
                          src={footerLogo}
                          alt="Footer Logo"
                        />
                      </div>
                      <div className="row no-gutters">
                        <div className="col-md-6 col-6">
                          <div className={styles.download}>
                            <p>Download Now</p>
                          </div>
                        </div>

                        <div className="col-md-6 col-6">
                          <Link
                            href="https://play.google.com/store/apps/details?id=com.intermind.senco"
                            passHref
                          >
                            <div>
                              <Image
                                className="img-fluid"
                                src={gplay}
                                alt="google play"
                              />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer_bottom}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 col-lg-5">
                  <Image src={footerlogo} alt="" className="img-fluid" />
                </div>
                <div className="col-md-8 col-lg-7 pb-3">
                  {/* <div className={styles.certificationbadge}>
                    <Image
                      src={certificationbadge}
                      alt=""
                      className="img-fluid"
                    />
                  </div> */}
                  <div className="d-flex justify-content-end w-100 align-items-center footer-top-important-img">
                    <div className="pe-3">
                      <div
                        style={{
                          height: 70,
                          width: 120,
                          position: "relative",
                        }}
                        className={styles.iso}
                      >
                        <Image
                          src={isoImage}
                          layout="fill"
                          alt="Lrqa Certified"
                        />
                      </div>
                    </div>
                    <div className="pe-3">
                      <div
                        style={{
                          height: 90,
                          width: 60,
                          position: "relative",
                        }}
                      >
                        <Image
                          style={{
                            height: 90,
                            width: 60,
                            position: "relative",
                          }}
                          src={certificationbadge}
                          alt="Great place to work Certified"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        height: 70,
                        width: 120,
                        position: "relative",
                      }}
                    >
                      <a
                        href="https://saq.panaceainfosec.com/index.php?certid=CERT08F119DFAE"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        <Image
                          style={{
                            height: 70,
                            width: 180,
                            position: "relative",

                            backgroundColor: "#fff",
                            paddingBottom: 10,
                            display: "inline-block",
                          }}
                          src={isoImage2}
                          alt="Great place to work Certified"
                          className="img-fluid"
                        />
                      </a>
                    </div>
                    {/* <div className="pe-0">
                      <a
                        style={{
                          height: 70,
                          width: 180,
                          position: "relative",

                          backgroundColor: "#fff",
                          paddingBottom: 10,
                          display: "inline-block",
                        }}
                        className={styles.iso}
                        href="https://saq.panaceainfosec.com/index.php?certid=CERT7FDB4D99F5"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          src={pci}
                          layout="fill"
                          alt="PCI  DSS Certified"
                        />
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className={styles.footer_bottom_link}>
                <div className="row">
                  {footerLink.map((v: any, ind) => (
                    <div className="col-lg-3 col-md-4 col-12 " key={ind}>
                      <div className={styles.footer_links}>
                        <ul>
                          <li>
                            <Link href={v.url} passHref>
                              {v.name}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {showTopBtn && (
                <div className={styles.go_to_top} onClick={goToTop}>
                  <i className="bi bi-capslock-fill"></i>
                </div>
              )}
              {showChatBotIcon && (
                <div
                  className="closed-widget-bottomRight launcher-wrapper"
                  onClick={loadChat}
                >
                  <div className="iconContainer">
                    <div
                      data-testid="launcher-icon"
                      className="launcher-icon-bottomRight round-icon-container"
                    >
                      <div className="avatar roundIcon"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="container">
              <div className="d-flex justify-content-between">
                {/* <div className="ps-1">
                  <div
                    style={{
                      height: 50,
                      width: 80,
                      position: "relative",
                      marginTop: 15,
                    }}
                    className={styles.iso}
                  >
                    <Image src={isoImage} layout="fill" alt="ISO" />
                  </div>
                </div>
                <div className="ps-1">
                  <div
                    style={{
                      height: 50,
                      width: 38,
                      position: "relative",
                      marginTop: 15,
                    }}
                  >
                    <Image
                      style={{
                        height: 50,
                        width: 38,
                        position: "relative",
                      }}
                      src={certificationbadge}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div> */}
                <div className="ps-2">
                  <div className={styles.copyright}>
                    <p>
                      &copy; {new Date().getFullYear()}
                      <strong>
                        <span> SENCO GOLD LTD</span>
                      </strong>
                      . All Rights Reserved.
                    </p>
                  </div>
                </div>

                <div className="ps-2">
                  <div>
                    <div className={styles.credits}>
                      {/* <li>
                        <Link href="/privacy-policy" passHref>
                          <a>Privacy Policy</a>
                        </Link>
                      </li>

                      <li>
                        <Link href="/shipping-policy" passHref>
                          <a>Shipping Policy</a>
                        </Link>
                      </li> */}

                      {/* <li>
                        <a href="#">Terms and Conditions </a>
                      </li> */}

                      {/* <li>
                        <Link href="/cancellation-policy" passHref>
                          <a>Cancellation & Refund Policy</a>
                        </Link>
                      </li> */}
                      <div className={styles.social_links1}>
                        <a
                          href="https://twitter.com/sencogoldindia"
                          className="twitter"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Twitter"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#fff"
                            className="bi bi-twitter-x"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                          </svg>
                        </a>
                        <a
                          href="https://www.facebook.com/SencoGoldAndDiamonds/"
                          className="facebook"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Facebook"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#fff"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                          </svg>
                        </a>
                        <a
                          href="https://www.youtube.com/user/sencogoldindia"
                          className="instagram"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Instagram"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#fff"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                          </svg>
                        </a>
                        <a
                          href="https://www.instagram.com/sencogoldanddiamonds"
                          className="google-plus"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Google Plus"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#fff"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                          </svg>
                        </a>
                        <a
                          href="https://api.whatsapp.com/send/?phone=%2B917605023222&amp;text&amp;app_absent=0"
                          className="linkedin"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Linkedin"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#fff"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ps-2">
                  <div>
                    <ul className={styles.credits}>
                      <li>
                        <Image
                          src={paymentimg}
                          alt="payment method logos"
                          className="img-fluid"
                          width={318}
                          height={50}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className={styles.footer_mobile}>
        <footer className="footer">
          <div className={styles.footer_top}>
            <Image
              src={footerbgimg}
              alt="Get Personalized Horoscope & Exclusive sneak peek to the latest collection"
            />
            <div className={styles.footer_top_a}>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-lg-5 col-7">
                    <div className={styles.middle_section}>
                      <div className={styles.h4}>
                        Get Personalized <span>Horoscope </span> <br />&
                        Exclusive sneak peek <br />
                        to the latest collection
                        <br />
                      </div>
                      <div className={styles.app_logo}>
                        <Image
                          className="img-fluid "
                          src={footerLogo}
                          alt="Footer Logo"
                        />
                      </div>
                      <div className="row no-gutters">
                        <div className="col-md-6 col-12">
                          <div className={styles.download}>
                            <p>Download Now</p>
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <Link
                            href="https://play.google.com/store/apps/details?id=com.intermind.senco"
                            passHref
                          >
                            <a className={styles.gpay} target="_blank">
                              <Image
                                className="img-fluid"
                                src={gplay}
                                alt="google play"
                              />
                            </a>
                          </Link>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.footer_bottom}>
            <div className="container">
              <div className="row">
                <div className="col-md-12 pb-3">
                  <Image
                    src={footerlogo}
                    alt="Footer Logo"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className={styles.footer_bottom_link}>
                <div className="row">
                  {footerLink.map((v: any, ind) => (
                    <div className="col-lg-3 col-md-6 col-6 " key={ind}>
                      <div className={styles.footer_links}>
                        <ul>
                          <li>
                            <Link href={v.url} passHref>
                              <a>{v.name}</a>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                {/* <div className="col-md-12 col-lg-7">
                  <div>
                    <ul className={styles.credits}>
                      <li>
                        <a href="#">Privacy Policy </a>
                      </li>

                      <li>
                        <a href="#">Shipping Policy </a>
                      </li>

                      <li>
                        <a href="#">Terms and Conditions </a>
                      </li>

                      <li>
                        <a href="#">Cancellation & Refund Policy </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="col-md-12 col-lg-5">
                  {showTopBtn && (
                    <div className={styles.go_to_top} onClick={goToTop}>
                      <i className="bi bi-capslock-fill"></i>
                    </div>
                  )}
                  {showChatBotIcon && (
                    <div
                      className="closed-widget-bottomRight launcher-wrapper"
                      onClick={loadChat}
                    >
                      <div className="iconContainer">
                        <div
                          data-testid="launcher-icon"
                          className="launcher-icon-bottomRight round-icon-container"
                        >
                          <div className="avatar roundIcon"></div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className={styles.copyright}>
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        style={{
                          height: 57,
                          width: 90,
                          position: "relative",
                          margin: "15px 5px",
                        }}
                      >
                        <Image
                          src={isoImage}
                          layout="fill"
                          alt="Lrqa Certified"
                        />
                      </div>
                      <div
                        style={{
                          height: 50,
                          width: 38,
                          position: "relative",
                          margin: "15px 5px",
                        }}
                      >
                        <Image
                          style={{
                            height: 50,
                            width: 38,
                            position: "relative",
                          }}
                          src={certificationbadge}
                          alt="Great place to work Certified"
                          className="img-fluid"
                        />
                      </div>
                      {/* <div
                        style={{
                          height: 50,
                          width: 38,
                          position: "relative",
                          margin: "15px 5px",
                        }}
                      >
                        <Image
                          style={{
                            height: 90,
                            width: 50,
                            position: "relative",
                            backgroundColor: "#fff",
                            paddingBottom: 10,
                            display: "inline-block",
                          }}
                          src={isoImage2}
                          alt="Great place to work Certified"
                          className="img-fluid"
                        />
                      </div> */}
                      <div
                        style={{
                          height: 50,
                          width: 130,
                          position: "relative",
                          margin: "15px 5px",
                        }}
                      >
                        <a
                          style={{
                            height: 50,
                            width: 130,
                            position: "relative",

                            backgroundColor: "#fff",
                            marginTop: 3,
                            display: "inline-block",
                          }}
                          className={styles.iso}
                          href="https://saq.panaceainfosec.com/index.php?certid=CERT08F119DFAE"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Image
                            src={isoImage2}
                            layout="fill"
                            alt="PCI DSS Certified"
                          />
                        </a>
                      </div>
                    </div>
                    <div className={styles.social_links1}>
                      <a
                        href="https://twitter.com/sencogoldindia"
                        className="twitter"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          className="bi bi-twitter-x"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                        </svg>
                      </a>
                      <a
                        href="https://www.facebook.com/SencoGoldAndDiamonds/"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                        </svg>
                      </a>
                      <a
                        href="https://www.youtube.com/user/sencogoldindia"
                        className="instagram"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                        </svg>
                      </a>
                      <a
                        href="https://www.instagram.com/sencogoldanddiamonds"
                        className="google-plus"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                        </svg>
                      </a>
                      <a
                        href="https://api.whatsapp.com/send/?phone=%2B917605023222&amp;text&amp;app_absent=0"
                        className="linkedin"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                        </svg>
                      </a>
                    </div>
                    <ul className={styles.credits}>
                      <li>
                        <Image
                          src={paymentimg}
                          alt="Payment method logos"
                          className="img-fluid"
                        />
                      </li>
                    </ul>
                    <p>
                      &copy; {new Date().getFullYear()}
                      <strong>
                        <span> SENCO GOLD LTD</span>
                      </strong>
                      . All Rights Reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      {/* iner page footer */}
      <div className="d-none">
        <div className={styles.inerpage_footer}>
          <div className="container">
            <div className="row mb-4">
              <div className="col-md-12 mt-3">
                <div className={styles.social_links}>
                  <a className="twitter" href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                    </svg>
                  </a>
                  <a className="facebook" href="#">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a className="instagram" href="#">
                    <i className="bi bi-youtube"></i>
                  </a>
                  <a className="google-plus" href="#">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a className="linkedin" href="#">
                    <i className="bi bi-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr />
          {/* <div className={styles.link_footer}>
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <p className={styles.copyright}>
                    © {new Date().getFullYear()} SENCO GOLD & DIAMONDS LTD. All
                    Rights Reserved.
                  </p>
                </div>
                <div className="col-md-7">
                  <ul className={styles.credits}>
                    <li>
                      <a href="#">Privacy Policy </a>
                    </li>
                    <li>
                      <a href="#">Shipping Policy </a>
                    </li>
                    <li>
                      <a href="#">Terms and Conditions </a>
                    </li>
                    <li>
                      <a href="#">Cancellation &amp; Refund Policy </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
